import React, {useEffect, useState} from 'react';
import {NavLink, useLocation} from "react-router-dom";
import Layout from "../../components/layout/layout";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import StartProject from "../../components/startProject";
import Accordion from "../../components/accordion/Accordion";
import Footer from "../../components/footer/footer";
import corporateWebsite from "../../img/services/corporate.png";
import onlineStore from "../../img/services/store.png";
import onlineCatalog from "../../img/services/catalog.png";
import webService from "../../img/services/service.png";
import landingPage from "../../img/services/landing.png";
import promoWebsite from "../../img/services/promo.png";
import useWindowSize from "../../utils/useWindowSize";
// import FaqComponent from "../../components/accordion/Accordion";

const ServicesLinks = () => {
    const CorporateWebsite = [
        {
            title: 'Business',
            description: ['Market analysis', 'Development of project prototypes', 'Customized design', 'Mobile Website Home Page Design', 'Basic SEO optimization*', 'Corporate mail configuration*'],
            content: ['Administrator website management', 'Product/service overview', 'Interactive map tagged', 'Photo gallery', 'Video gallery', 'Customized marketing tools', 'Share products/articles via social networks*', 'Capture forms', 'Multilanguage', 'Subscription system', 'Integration of news feeds/posts via social networks*', 'Connecting Google Analytics']
        },
        {
            title: 'Custom',
            description: ['Market analysis', 'Development of project prototypes', 'Premium design with a complex structure', 'Elements of Graphics Design', 'Animation and WOW effects*', '3D graphics/360*', 'Unique design of mobile version', 'Basic SEO optimization*', 'Corporate mail configuration*'],
            content: ['Administrator website management', 'Product/service overview', 'Interactive map tagged', 'Photo gallery', 'Video gallery', 'Customized marketing tools', 'Sales triggers', 'Synchronization with CRM systems*', 'Share products/articles via social networks*', 'Capture forms', 'Multilanguage', 'Subscription system', 'Integration of news feeds/posts via social networks*', 'Connecting Google Analytics', 'Turnkey website content filling*']
        },
        {
            title: 'WOW',
            description: ['Want something new and unusual?', 'Wish to stand out?', 'Wanna be at the top?', 'Dream to conquer space?'],
            content: ['Then click!']
        }
    ]
    const location = useLocation();
    const {width} = useWindowSize()
    console.log(width)
    const [scrollPosition, setScrollPosition] = useState(50);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getToPrice = () => {
        console.log('price')
    }

    return (
        <>
            <Layout>
                <section className="bread-crumbs">
                    <NavLink to="/">home</NavLink><span> /</span>
                    <NavLink className="link-right" to="/service">service</NavLink><span> /</span>
                    <NavLink className="link-right" to="/service/development">development</NavLink>
                </section>
                {location.pathname === '/service/development/corporate-website' &&
                <>
                    <div className="corporate-website_top">

                        <div className="corporate-website_top_left">
                            <h1>Corporate website</h1>
                            <h2>We develop craft corporate websites</h2>
                            <p>Corporate website is representation of your company on the internet. This type of website
                                reveals the
                                essence of the company, forms your image and brand.</p>
                            <p>Corporate website is not just a source of information, or a company advertisement – it is
                                a
                                valuable
                                management
                                resource. It helps to solve a complex set of tasks within your organization. Corporate
                                website is
                                not only the
                                ‘face’ of the company, but also a reliable assistant in communications with clients.</p>
                        </div>
                        <div className="our-services_content-img">
                            <img src={corporateWebsite}
                                 className={scrollPosition > 80 && scrollPosition < 1800 && width > 800 ? "slip corporate-website" : "corporate-website"}
                                 alt="designWeb"/>
                        </div>
                    </div>
                    <StartProject/>
                    <div className="tariff-plans_items">
                        {CorporateWebsite.map(c => (
                            <div className="tariff-plans_item corporate-website" key={c.title}>
                                <SimpleBar style={{maxHeight: 500}}>
                                    <div className="tariff-top">
                                        <h3>{c.title}</h3>
                                        {c.description.map(i => (
                                            <><span key={i}>{i}</span><br/></>
                                        ))}
                                    </div>
                                    <div className="tariff-middle">
                                        <span style={{marginLeft: 20}}>Functional component:</span>
                                        <ul>
                                            {c.content.map(i => (
                                                <li key={i}>{i}</li>
                                            ))}
                                        </ul>
                                    </div>

                                </SimpleBar>
                                <div className="tariff-bottom">
                                    <button onClick={getToPrice}>Find out the price</button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="our-technical-stack">
                        <div className="our-technical-stack_top">
                            <div className="our-technical-stack_top_title">
                                <h2>Our technical stack during development</h2>
                            </div>

                            <div className="our-technical-stack_top_description">
                                <p>Choosing the right technology stack during development is a factor of a successful
                                    project.</p>
                                <p>Proper website design is not only about writing bug-free code, but also about
                                    optimization,
                                    future scalability, and security.</p>
                            </div>
                        </div>
                        <div className="our-technical-stack_items">
                            <div className="our-technical-stack_item">
                                <div className="title"><p>Framework:</p><span>React.js, Nest.js, Django</span></div>
                                <div className="description"><p>Flexibility. Due to the framework, you can implement any
                                    idea without fighting with CMS.
                                    A web built on CMS will always be slower and less customizable than framework-based
                                    one.</p></div>
                            </div>
                            <div className="our-technical-stack_item">
                                <div className="title"><p>Integrations:</p><span>CRM / API / Information services / Chatbots / Analytics / Personal account, etc.</span>
                                </div>
                                <div className="description"><p>Automate your site with CRM synchronization. Process
                                    feedback requests directly in your CRM*.
                                    Chatbots will help you save your time answering common questions</p></div>
                            </div>
                        </div>
                    </div>
                    <div className="stages-development">
                        <div className="stages-development_top">
                            <h2>Stages of Development</h2>
                            <p>The web design process and clear processing stages - is a key of a successful
                                project.</p>
                            <Accordion/>
                        </div>
                    </div>

                </>
                }
                {location.pathname === '/service/development/online-catalog' &&
                <>
                    <div className="corporate-website_top">
                        <div className="corporate-website_top_left">
                            <h1>Online catalog</h1>
                            <h2>We develop craft online catalog</h2>
                            <p>Online catalog is representation of your company on the internet. This type of website
                                reveals the
                                essence of the company, forms your image and brand.</p>
                            <p>Corporate website is not just a source of information, or a company advertisement – it is
                                a
                                valuable
                                management
                                resource. It helps to solve a complex set of tasks within your organization. Corporate
                                website is
                                not only the
                                ‘face’ of the company, but also a reliable assistant in communications with clients.</p>
                        </div>
                        <div className="our-services_content-img">
                            <img src={onlineCatalog}
                                 className={scrollPosition > 80 && scrollPosition < 1800 && width > 800 ? "slip corporate-website" : "corporate-website"}
                                 alt="designWeb"/>
                        </div>
                    </div>
                    <StartProject/>
                    <div className="tariff-plans_items">
                        {CorporateWebsite.map(c => (
                            <div className="tariff-plans_item corporate-website" key={c.title}>
                                <SimpleBar style={{maxHeight: 500}}>
                                    <div className="tariff-top">
                                        <h3>{c.title}</h3>
                                        {c.description.map(i => (
                                            <><span key={i}>{i}</span><br/></>
                                        ))}
                                    </div>
                                    <div className="tariff-middle">
                                        <span style={{marginLeft: 20}}>Functional component:</span>
                                        <ul>
                                            {c.content.map(i => (
                                                <li key={i}>{i}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="tariff-bottom">
                                        <button onClick={getToPrice}>Find out the price</button>
                                    </div>
                                </SimpleBar>
                            </div>
                        ))}
                    </div>
                    <div className="our-technical-stack">
                        <div className="our-technical-stack_top">
                            <div className="our-technical-stack_top_title">
                                <h2>Our technical stack during development</h2>
                            </div>

                            <div className="our-technical-stack_top_description">
                                <p>Choosing the right technology stack during development is a factor of a successful
                                    project.</p>
                                <p>Proper website design is not only about writing bug-free code, but also about
                                    optimization,
                                    future scalability, and security.</p>
                            </div>
                        </div>
                        <div className="our-technical-stack_items">
                            <div className="our-technical-stack_item">
                                <div className="title"><p>Framework:</p><span>React.js, Nest.js, Django</span></div>
                                <div className="description"><p>Flexibility. Due to the framework, you can implement any
                                    idea without fighting with CMS.
                                    A web built on CMS will always be slower and less customizable than framework-based
                                    one.</p></div>
                            </div>
                            <div className="our-technical-stack_item">
                                <div className="title"><p>Integrations:</p><span>CRM / API / Information services / Chatbots / Analytics / Personal account, etc.</span>
                                </div>
                                <div className="description"><p>Automate your site with CRM synchronization. Process
                                    feedback requests directly in your CRM*.
                                    Chatbots will help you save your time answering common questions</p></div>
                            </div>
                        </div>
                    </div>
                    <div className="stages-development">
                        <div className="stages-development_top">
                            <h2>Stages of Development</h2>
                            <p>The web design process and clear processing stages - is a key of a successful
                                project.</p>
                            <Accordion/>
                        </div>
                    </div>
                </>
                }
                {location.pathname === '/service/development/online-store' &&
                <>
                    <div className="corporate-website_top">
                        <div className="corporate-website_top_left">
                            <h1>Online store</h1>
                            <h2>We develop craft online store</h2>
                            <p>Online store is representation of your company on the internet. This type of website
                                reveals
                                the
                                essence of the company, forms your image and brand.</p>
                            <p>Online store is not just a source of information, or a company advertisement – it is a
                                valuable
                                management
                                resource. It helps to solve a complex set of tasks within your organization. Corporate
                                website is
                                not only the
                                ‘face’ of the company, but also a reliable assistant in communications with clients.</p>
                        </div>
                        <div className="our-services_content-img">
                            <img src={onlineStore}
                                 className={scrollPosition > 80 && scrollPosition < 1800 && width > 800 ? "slip corporate-website" : "corporate-website"}
                                 alt="designWeb"/>
                        </div>
                    </div>
                    <StartProject/>
                    <div className="tariff-plans_items">
                        {CorporateWebsite.map(c => (
                            <div className="tariff-plans_item corporate-website">
                                <SimpleBar style={{maxHeight: 500}}>
                                    <div className="tariff-top">
                                        <h3>{c.title}</h3>
                                        {c.description.map(i => (
                                            <><span>{i}</span><br/></>
                                        ))}
                                    </div>
                                    <div className="tariff-middle">
                                        <span style={{marginLeft: 20}}>Functional component:</span>
                                        <ul>
                                            {c.content.map(i => (
                                                <li>{i}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="tariff-bottom">
                                        <button onClick={getToPrice}>Find out the price</button>
                                    </div>
                                </SimpleBar>
                            </div>
                        ))}
                    </div>
                    <div className="our-technical-stack">
                        <div className="our-technical-stack_top">
                            <div className="our-technical-stack_top_title">
                                <h2>Our technical stack during development</h2>
                            </div>

                            <div className="our-technical-stack_top_description">
                                <p>Choosing the right technology stack during development is a factor of a successful
                                    project.</p>
                                <p>Proper website design is not only about writing bug-free code, but also about
                                    optimization,
                                    future scalability, and security.</p>
                            </div>
                        </div>
                        <div className="our-technical-stack_items">
                            <div className="our-technical-stack_item">
                                <div className="title"><p>Framework:</p><span>React.js, Nest.js, Django</span></div>
                                <div className="description"><p>Flexibility. Due to the framework, you can implement any
                                    idea without fighting with CMS.
                                    A web built on CMS will always be slower and less customizable than framework-based
                                    one.</p></div>
                            </div>
                            <div className="our-technical-stack_item">
                                <div className="title"><p>Integrations:</p><span>CRM / API / Information services / Chatbots / Analytics / Personal account, etc.</span>
                                </div>
                                <div className="description"><p>Automate your site with CRM synchronization. Process
                                    feedback requests directly in your CRM*.
                                    Chatbots will help you save your time answering common questions</p></div>
                            </div>
                        </div>
                    </div>
                    <div className="stages-development">
                        <div className="stages-development_top">
                            <h2>Stages of Development</h2>
                            <p>The web design process and clear processing stages - is a key of a successful
                                project.</p>
                            <Accordion/>
                        </div>
                    </div>
                </>
                }
                {location.pathname === '/service/development/web-service' &&
                <>
                    <div className="corporate-website_top">
                        <div className="corporate-website_top_left">
                            <h1>Online catalog</h1>
                            <h2>We develop craft online catalog</h2>
                            <p>Online catalog is representation of your company on the internet. This type of website
                                reveals the
                                essence of the company, forms your image and brand.</p>
                            <p>Corporate website is not just a source of information, or a company advertisement – it is
                                a
                                valuable
                                management
                                resource. It helps to solve a complex set of tasks within your organization. Corporate
                                website is
                                not only the
                                ‘face’ of the company, but also a reliable assistant in communications with clients.</p>
                        </div>
                        <div className="our-services_content-img">
                            <img src={webService}
                                 className={scrollPosition > 80 && scrollPosition < 1800 && width > 800 ? "slip corporate-website" : "corporate-website"}
                                 alt="designWeb"/>
                        </div>
                    </div>
                    <StartProject/>
                    <div className="tariff-plans_items">
                        {CorporateWebsite.map(c => (
                            <div className="tariff-plans_item corporate-website">
                                <SimpleBar style={{maxHeight: 500}}>
                                    <div className="tariff-top">
                                        <h3>{c.title}</h3>
                                        {c.description.map(i => (
                                            <><span>{i}</span><br/></>
                                        ))}
                                    </div>
                                    <div className="tariff-middle">
                                        <span style={{marginLeft: 20}}>Functional component:</span>
                                        <ul>
                                            {c.content.map(i => (
                                                <li>{i}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="tariff-bottom">
                                        <button onClick={getToPrice}>Find out the price</button>
                                    </div>
                                </SimpleBar>
                            </div>
                        ))}
                    </div>
                    <div className="our-technical-stack">
                        <div className="our-technical-stack_top">
                            <div className="our-technical-stack_top_title">
                                <h2>Our technical stack during development</h2>
                            </div>

                            <div className="our-technical-stack_top_description">
                                <p>Choosing the right technology stack during development is a factor of a successful
                                    project.</p>
                                <p>Proper website design is not only about writing bug-free code, but also about
                                    optimization,
                                    future scalability, and security.</p>
                            </div>
                        </div>
                        <div className="our-technical-stack_items">
                            <div className="our-technical-stack_item">
                                <div className="title"><p>Framework:</p><span>React.js, Nest.js, Django</span></div>
                                <div className="description"><p>Flexibility. Due to the framework, you can implement any
                                    idea without fighting with CMS.
                                    A web built on CMS will always be slower and less customizable than framework-based
                                    one.</p></div>
                            </div>
                            <div className="our-technical-stack_item">
                                <div className="title"><p>Integrations:</p><span>CRM / API / Information services / Chatbots / Analytics / Personal account, etc.</span>
                                </div>
                                <div className="description"><p>Automate your site with CRM synchronization. Process
                                    feedback requests directly in your CRM*.
                                    Chatbots will help you save your time answering common questions</p></div>
                            </div>
                        </div>
                    </div>
                    <div className="stages-development">
                        <div className="stages-development_top">
                            <h2>Stages of Development</h2>
                            <p>The web design process and clear processing stages - is a key of a successful
                                project.</p>
                            <Accordion/>
                        </div>
                    </div>
                </>
                }
                {location.pathname === '/service/development/landing-page' &&
                <>
                    <div className="corporate-website_top">
                        <div className="corporate-website_top_left">
                            <h1>Online catalog</h1>
                            <h2>We develop craft online catalog</h2>
                            <p>Online catalog is representation of your company on the internet. This type of website
                                reveals the
                                essence of the company, forms your image and brand.</p>
                            <p>Corporate website is not just a source of information, or a company advertisement – it is
                                a
                                valuable
                                management
                                resource. It helps to solve a complex set of tasks within your organization. Corporate
                                website is
                                not only the
                                ‘face’ of the company, but also a reliable assistant in communications with clients.</p>
                        </div>
                        <div className="our-services_content-img">
                            <img src={landingPage}
                                 className={scrollPosition > 80 && scrollPosition < 1800 && width > 800 ? "slip corporate-website" : "corporate-website"}
                                 alt="designWeb"/>
                        </div>
                    </div>
                    <StartProject/>
                    <div className="tariff-plans_items">
                        {CorporateWebsite.map(c => (
                            <div className="tariff-plans_item corporate-website">
                                <SimpleBar style={{maxHeight: 500}}>
                                    <div className="tariff-top">
                                        <h3>{c.title}</h3>
                                        {c.description.map(i => (
                                            <><span>{i}</span><br/></>
                                        ))}
                                    </div>
                                    <div className="tariff-middle">
                                        <span style={{marginLeft: 20}}>Functional component:</span>
                                        <ul>
                                            {c.content.map(i => (
                                                <li>{i}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="tariff-bottom">
                                        <button onClick={getToPrice}>Find out the price</button>
                                    </div>
                                </SimpleBar>
                            </div>
                        ))}
                    </div>
                    <div className="our-technical-stack">
                        <div className="our-technical-stack_top">
                            <div className="our-technical-stack_top_title">
                                <h2>Our technical stack during development</h2>
                            </div>

                            <div className="our-technical-stack_top_description">
                                <p>Choosing the right technology stack during development is a factor of a successful
                                    project.</p>
                                <p>Proper website design is not only about writing bug-free code, but also about
                                    optimization,
                                    future scalability, and security.</p>
                            </div>
                        </div>
                        <div className="our-technical-stack_items">
                            <div className="our-technical-stack_item">
                                <div className="title"><p>Framework:</p><span>React.js, Nest.js, Django</span></div>
                                <div className="description"><p>Flexibility. Due to the framework, you can implement any
                                    idea without fighting with CMS.
                                    A web built on CMS will always be slower and less customizable than framework-based
                                    one.</p></div>
                            </div>
                            <div className="our-technical-stack_item">
                                <div className="title"><p>Integrations:</p><span>CRM / API / Information services / Chatbots / Analytics / Personal account, etc.</span>
                                </div>
                                <div className="description"><p>Automate your site with CRM synchronization. Process
                                    feedback requests directly in your CRM*.
                                    Chatbots will help you save your time answering common questions</p></div>
                            </div>
                        </div>
                    </div>
                    <div className="stages-development">
                        <div className="stages-development_top">
                            <h2>Stages of Development</h2>
                            <p>The web design process and clear processing stages - is a key of a successful
                                project.</p>
                            <Accordion/>
                        </div>
                    </div>
                </>
                }
                {location.pathname === '/service/development/promo-website' &&
                <>
                    <div className="corporate-website_top">
                        <div className="corporate-website_top_left">
                            <h1>Promo website</h1>
                            <h2>We develop craft online catalog</h2>
                            <p>Promo website is representation of your company on the internet. This type of website
                                reveals the
                                essence of the company, forms your image and brand.</p>
                            <p>Promo website is not just a source of information, or a company advertisement – it is a
                                valuable
                                management
                                resource. It helps to solve a complex set of tasks within your organization. Corporate
                                website is
                                not only the
                                ‘face’ of the company, but also a reliable assistant in communications with clients.</p>
                        </div>
                        <div className="our-services_content-img">
                            <img src={promoWebsite}
                                 className={scrollPosition > 80 && scrollPosition < 1800 && width > 800 ? "slip corporate-website" : "corporate-website"}
                                 alt="designWeb"/>
                        </div>
                    </div>
                    <StartProject/>
                    <div className="tariff-plans_items">
                        {CorporateWebsite.map(c => (
                            <div className="tariff-plans_item corporate-website">
                                <SimpleBar style={{maxHeight: 500}}>
                                    <div className="tariff-top">
                                        <h3>{c.title}</h3>
                                        {c.description.map(i => (
                                            <><span>{i}</span><br/></>
                                        ))}
                                    </div>
                                    <div className="tariff-middle">
                                        <span style={{marginLeft: 20}}>Functional component:</span>
                                        <ul>
                                            {c.content.map(i => (
                                                <li>{i}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="tariff-bottom">
                                        <button onClick={getToPrice}>Find out the price</button>
                                    </div>
                                </SimpleBar>
                            </div>
                        ))}
                    </div>
                    <div className="our-technical-stack">
                        <div className="our-technical-stack_top">
                            <div className="our-technical-stack_top_title">
                                <h2>Our technical stack during development</h2>
                            </div>

                            <div className="our-technical-stack_top_description">
                                <p>Choosing the right technology stack during development is a factor of a successful
                                    project.</p>
                                <p>Proper website design is not only about writing bug-free code, but also about
                                    optimization,
                                    future scalability, and security.</p>
                            </div>
                        </div>
                        <div className="our-technical-stack_items">
                            <div className="our-technical-stack_item">
                                <div className="title"><p>Framework:</p><span>React.js, Nest.js, Django</span></div>
                                <div className="description"><p>Flexibility. Due to the framework, you can implement any
                                    idea without fighting with CMS.
                                    A web built on CMS will always be slower and less customizable than framework-based
                                    one.</p></div>
                            </div>
                            <div className="our-technical-stack_item">
                                <div className="title"><p>Integrations:</p><span>CRM / API / Information services / Chatbots / Analytics / Personal account, etc.</span>
                                </div>
                                <div className="description"><p>Automate your site with CRM synchronization. Process
                                    feedback requests directly in your CRM*.
                                    Chatbots will help you save your time answering common questions</p></div>
                            </div>
                        </div>
                    </div>
                    <div className="stages-development">
                        <div className="stages-development_top">
                            <h2>Stages of Development</h2>
                            <p>The web design process and clear processing stages - is a key of a successful
                                project.</p>
                            <Accordion/>
                        </div>
                    </div>
                </>
                }
                <StartProject/>

            </Layout>
            <Footer/>
        </>
    );
};

export default ServicesLinks;