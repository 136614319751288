import {CHANGE_MODAL, CLOSE_MODAL} from './type'

const initialState = {
    modalBar: true,
}

export default function modalReducer(state = initialState, action) {
    switch (action.type) {
        case  CHANGE_MODAL:
            return {
                ...state,
                modalBar: !state.modalBar,
            }
        case  CLOSE_MODAL:
            return {
                ...state,
                modalBar: true,
            }
        default:
            return state
    }
}