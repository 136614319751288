import React from 'react';
import Header from "../header/header";
import BoxLeft from "../header/box-left";
import ScrollToTop from "../ScrollToTop";
import UseEscape from "../useEscape";

const Layout = ({children}) => {
    return (
        <>

            <Header/>
            <BoxLeft/>
            <div className="page_content">
                <div className="container">
                    {children}
                </div>
            </div>
            <ScrollToTop/>
        </>
    );
};

export default Layout;