export const links = [
    {
        title: "Home",
        href: "/",
        path: "/home",
    },
    {
        title: "Portfolio",
        href: "/portfolio",
        path: "/portfolio",
    },
    {
        title: "Services",
        href: "/service",
        path: "/service",
        child: {
            "design":{title:"design", description:"sample text design"},
            "development":{title:"development", description:"sample text development"},
            "seo":{title:"seo", description:"sample text seo"},
        }
    },
    {
        title: "About",
        href: "/about",
        path: "/about",
    },

    {
        title: "Contact",
        href: "/contact",
        path: "/contact",
    },
];
