import React from 'react';
import Layout from "../components/layout/layout";
import data from '../data/data.json'
import {InView} from "react-intersection-observer";
import {headerVariants_2} from "../components/animateVariants";
import {motion} from "framer-motion";
import {NavLink} from "react-router-dom";
import Footer from "../components/footer/footer";
import useWindowSize from "../utils/useWindowSize";

const Portfolio = () => {
    const {width} = useWindowSize()

    return (
        <>
            <Layout>
                <section className="bread-crumbs">
                    <NavLink to="/">home</NavLink>
                    {/*<NavLink className="link-right" to="/portfolio">portfolio</NavLink>*/}
                </section>
                <div className="portfolio-title">
                    <h1>Portfolio</h1>
                </div>

                <div className="portfolio-items">
                    {data.projects.map(project => {
                        if (width > 500){
                            return (
                                    <InView triggerOnce key={project.name}>
                                        {({inView, ref}) => (
                                            <motion.div className="portfolio-item"
                                                        variants={headerVariants_2}
                                                        initial="hidden"
                                                        animate={inView ? "show" : "hidden"}
                                                        exit="exit"
                                                        ref={ref}
                                                        style={{
                                                            background: `linear-gradient(${project.bannerColor.primary}, ${project.bannerColor.secondary})`,
                                                        }}
                                            >
                                                <div className="portfolio-item_info">
                                                    <span>{project.category}</span>
                                                </div>
                                                <NavLink to={project.slug}>
                                                    <h2 key={project.url}>{project.name}</h2>
                                                    <img src={project.heroImage} alt=""/>
                                                    {/*{project.details.map(detail => (*/}

                                                    {/*    <div>*/}
                                                    {/*        /!*<p  key={project.title}>{detail.title}</p>*!/*/}
                                                    {/*        /!*<p key={project.description}>{detail.description}</p>*!/*/}
                                                    {/*        /!*<a href={project.url} target="_blank">{project.url}</a>*!/*/}
                                                    {/*    </div>*/}

                                                    {/*))}*/}
                                                </NavLink>
                                                <div className="portfolio-item_nav">
                                                    <span>{project.year}</span>
                                                </div>
                                            </motion.div>
                                        )}
                                    </InView>
                                )

                        } else {
                            return (

                                        <div className="portfolio-item"
                                                    style={{
                                                        background: `linear-gradient(${project.bannerColor.primary}, ${project.bannerColor.secondary})`,
                                                    }}
                                        >
                                            <div className="portfolio-item_info">
                                                <span>{project.category}</span>
                                            </div>
                                            <NavLink to={project.slug}>
                                                <h2 key={project.url}>{project.name}</h2>
                                                <img src={project.heroImage} alt=""/>
                                                {/*{project.details.map(detail => (*/}

                                                {/*    <div>*/}
                                                {/*        /!*<p  key={project.title}>{detail.title}</p>*!/*/}
                                                {/*        /!*<p key={project.description}>{detail.description}</p>*!/*/}
                                                {/*        /!*<a href={project.url} target="_blank">{project.url}</a>*!/*/}
                                                {/*    </div>*/}

                                                {/*))}*/}
                                            </NavLink>
                                            <div className="portfolio-item_nav">
                                                <span>{project.year}</span>
                                            </div>
                                        </div>
                            )
                        }

                    })}
                </div>

                <p style={{cursor: 'pointer'}}>more ...</p>
            </Layout>
            <Footer/>
        </>
    );
};

export default Portfolio;