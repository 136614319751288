import React, {useState} from 'react';
import Layout from "../components/layout/layout";
import Footer from "../components/footer/footer";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import {motion} from "framer-motion"
import useWindowSize from "../utils/useWindowSize";
import axios from "axios";
import {animateVariants} from "../components/animateVariants";
import {InView} from "react-intersection-observer";
import bg from "../img/contact/bird.gif";

let formSchema = Yup.object().shape({
    name: Yup.string()
        .matches(/^[A-Za-zА-яа-я ]*$/, 'please enter valid name')
        .max(40)
        .required(),
    email: Yup.string()
        .email("invalid email")
        .required(),
    message: Yup.string().required("required field")
});
const Contact = () => {
    const {width} = useWindowSize()


    // const handleCursorHover = (isHover) => {
    //     dispatch({
    //         type: actionType.setGlobalCursorHover,
    //         value: isHover,
    //     });
    // };

    const [serverState, setServerState] = useState();
    const handleServerResponse = (ok, msg) => {
        setServerState({ok, msg});
    };
    // const success = () => {
    //     message.success('This is a success message');
    // };
    //
    // const error = () => {
    //     message.error('This is an error message');
    // };
    const handleOnSubmit = (values, actions) => {
        axios({
            method: "POST",
            // url: "https://formspree.io/f/xwkakdpg",
            url: "https://formspree.io/f/mrgrdjvl", // chrome formspree
            data: values
        })
            .then(response => {
                actions.setSubmitting(false);
                actions.resetForm();
                handleServerResponse(true, "Thanks!");
            })
            .catch(error => {
                actions.setSubmitting(false);
                handleServerResponse(false, error.response.data.error);
            });
    };
    return (
        <>
            <Layout>

                <section className="my-form">

                    <div className="form__wrapper">


                        <h3 className="form__title">Let's do something together!
                            We are waiting for your project!
                        </h3>
                        {/*<p className="form__description">{t('form.form__description')}</p>*/}
                        <Formik
                            initialValues={{name: "", email: "", message: ""}}
                            onSubmit={handleOnSubmit}
                            validationSchema={formSchema}
                        >
                            {({isSubmitting}) => (
                                <Form id="fs-frm" noValidate>
                                    <InView>
                                        {({inView, ref}) => (
                                            <motion.div className="fields"
                                                        variants={animateVariants.divider}
                                                        initial="hidden"
                                                        animate={inView ? "show" : "hidden"}
                                                        exit="exit"
                                                        ref={ref}
                                            >
                                                <div className="field">
                                                    {/*<label htmlFor="name">Name:</label>*/}
                                                    <Field id="name" type="text" name="name" placeholder="name"/>
                                                    <ErrorMessage name="name" className="errorMsg" component="p"/>
                                                </div>
                                                <div className="field">
                                                    {/*<label htmlFor="email">Email:</label>*/}
                                                    <Field id="email" type="email" name="email" placeholder="email"/>
                                                    <ErrorMessage name="email" className="errorMsg" component="p"/>
                                                </div>

                                                <div className="field message">
                                                    {/*<label htmlFor="message">Message:</label>*/}
                                                    <Field id="message" name="message" component="textarea"
                                                           placeholder="message"/>
                                                    <ErrorMessage name="message" className="errorMsg" component="p"/>
                                                </div>
                                                <div className="field field__button">
                                                    {/*<motion.button*/}
                                                    {/*    type="submit"*/}
                                                    {/*    disabled={isSubmitting}*/}
                                                    {/*>*/}
                                                    {/*    submit*/}
                                                    {/*</motion.button>*/}
                                                    <InView>
                                                        {({inView, ref}) => (
                                                            <motion.button
                                                                className="contact-with-us"
                                                                variants={animateVariants.text}
                                                                initial="hidden"
                                                                animate={inView ? "show" : "hidden"}
                                                                exit="exit"
                                                                ref={ref}
                                                            ><h4>submit</h4>
                                                            </motion.button>
                                                        )}
                                                    </InView>
                                                </div>

                                                {serverState && (
                                                    <p className={!serverState.ok ? "errorMsg" : "success"}>
                                                        {serverState.msg}
                                                    </p>
                                                )}
                                            </motion.div>
                                        )}
                                    </InView>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    {/*<img src={bg} alt=""/>*/}
                </section>
            </Layout>
            {/*<Footer/>*/}
        </>
    );
};

export default Contact;