import {createStore, combineReducers, applyMiddleware} from "redux";
import thunk from "redux-thunk";

import {composeWithDevTools} from 'redux-devtools-extension';
import modalReducer from "./modal/modalReducer";
import servicesReducer from "./services/servicesReducer";
import cursorReducer from "./cursor/cursorReducer";
const store = createStore(
    combineReducers({
        modal: modalReducer,
        services: servicesReducer,
        cursor: cursorReducer,
    }),
    composeWithDevTools(applyMiddleware(thunk))
);

export default store;