import {Collapse} from 'antd';

const {Panel} = Collapse;
const text = [
    {
        key: "1",
        title: "Step 1: Start of the project, GO!",
        description: ['Project analysis','Commercial offer','Contract execution','1st part of payment is 30%'],
    },
    {
        key: "2",
        title: "Step 2: Let’s go! This is a prototype, a tech task!",
        description: ['Creating a website prototype','Upload prototype to InVision software','Prototype correction and approval','Writing a technical task','Approval of a technical task'],
    },
    {
        key: "3",
        title: "Step 3: Let’s go! Design, graphics and animation",
        description: ['The second part of the payment is 20%','Home page design','Inner page design','Unique graphic elements*','Block animation*','Development of adaptive design','Final Design Approval'],
    },
    {
        key: "4",
        title: "Step 4: Let’s code! <\\>",
        description: ['The third part of the payment is 25%','Layout','Website development','Additional tools connection and installation*'],
    },
    {
        key: "5",
        title: "Step 5: It is almost ready! Time for test!",
        description: ['Test site content*','Project testing','Final payment is 25%','Domain registration*','Export to hosting or to the server part*'],
    },
    {
        key: "6",
        title: "Step 6: Export to hosting or to the server part*",
        description: ['Launch and training','Warranty period*'],
    }
];

const Accordion = () => (

        <Collapse accordion>
            {text.map(t => (
                <Panel style={{fontSize:22, color:'#dec5b5'}} header={t.title} key={t.key}>
                    <ul>
                        {t.description.map(d =>(
                            <li>{d}</li>
                        ))}
                    </ul>

                </Panel>
            ))}

            {/*<Panel header="Step 2: Let’s go! This is a prototype, a tech task!" key="2">*/}
            {/*    <p>{text}</p>*/}
            {/*</Panel>*/}
            {/*<Panel header="Step 3: Design, graphics and animation" key="3">*/}
            {/*    <p>{text}</p>*/}
            {/*</Panel>*/}
        </Collapse>

);


export default Accordion;