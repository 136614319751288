import React, {useEffect, useState} from 'react';
import Layout from "../components/layout/layout";
import {animateVariants, headerVariants_2} from "../components/animateVariants";
import {NavLink} from 'react-router-dom'
import {motion} from "framer-motion";
import {HeroBg, VideoBg, HeroImg} from "./home/HomeElements";
import Video from "../../src/videos/Abstract_black_4K.mp4";
import designWeb from "../img/home/design_web.png"
import bg from "../img/home/bg_2.webp"
import arrow from '../img/arrow-east.svg'
import Footer from "../components/footer/footer";
import useWindowSize from "../utils/useWindowSize";
import {InView} from "react-intersection-observer";
import SimpleBar from "simplebar-react";
import OurServices from "../components/our-services/our-services";
import hover from "../components/hover";

const Home = () => {
    const contents = [
        {
            href: '/service/design',
            title: 'Design',
            content: 'Website design is not just an external design of your resource. First of all, it is Web usability. The main task of the designer, besides the originality of the work, is competent marketing planning, maximum customer retention, and desired action.'
        },
        {
            href: '/service/development',
            title: 'Development',
            content: 'We create websites of any complexity. We help to fulfill your wishes, meet your needs: from the simplest to individual and non-standard ideas.'
        },
        {
            href: '/service/seo',
            title: 'SEO and promotion',
            content: 'SEO stands for “search engine optimization.” In simple terms, it means the process of improving your site to increase its visibility when people search for products or services related to your business in Google, Bing, and other search engines.'
        }
    ]
    const {width} = useWindowSize()
    const [scrollPosition, setScrollPosition] = useState(0);
    console.log(scrollPosition)
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // let {handleMouseOver, handleMouseOut} = hover()
    return (
        <>
            <Layout>

                {width > 500 ?
                    <HeroBg>
                        <VideoBg autoPlay loop muted type="video/mp4" src={Video}/>
                    </HeroBg>
                :
                // <div className="mobile_bg"></div>
                    <HeroBg>
                        <HeroImg src={bg}/>
                    </HeroBg>
                }

                <section className="home-top">
                    {width > 500 ?
                        <>
                            <InView triggerOnce>
                                {({inView, ref}) => (
                                    <motion.div
                                        className="home-header"
                                        variants={animateVariants.divider}
                                        initial="hidden"
                                        animate={inView ? "show" : "hidden"}
                                        exit="exit"
                                        ref={ref}
                                    >
                                        <h2 className="design">UI Design</h2>
                                        <h2 className="development">Web development</h2>
                                        <h2 className="blockchain">SEO and promotion</h2>

                                    </motion.div>
                                )}
                            </InView>
                            <div className="wrap-link">
                                <NavLink to="/contact" exact="true">
                                    <InView triggerOnce>
                                        {({inView, ref}) => (
                                            <motion.div
                                                className="contact-with-us"
                                                variants={animateVariants.text}
                                                initial="hidden"
                                                animate={inView ? "show" : "hidden"}
                                                exit="exit"
                                                ref={ref}
                                            ><h4>contact with us</h4>
                                            </motion.div>
                                        )}
                                    </InView>
                                </NavLink>
                            </div>
                        </>
                        :
                        <>
                            <div
                                className="home-header">
                                <h2 className="design">UI Design</h2>
                                <h2 className="development">Web development</h2>
                                <h2 className="blockchain">SEO and promotion</h2>
                            </div>
                            <div className="wrap-link">
                                <NavLink to="/contact" exact="true">
                                    <div
                                        className="contact-with-us">
                                        <h4>contact with us</h4>
                                    </div>
                                </NavLink>
                            </div>
                        </>
                    }
                </section>

                <section className="our-services">
                    <h2 className="our-services_title">Our services</h2>
                    <div className="our-services_header">
                        <p>We create bright websites that are easy to use. Communications are a cinch between customer
                            and support while advertising your product.</p>
                    </div>
                    <div className="wrapper-our-services_content">
                        <div className="our-services_content-img">

                            <img src={designWeb}
                                 className={scrollPosition > 1080 && scrollPosition < 4900 && width > 860 ? "slip" : ""}
                                 alt="designWeb"/>
                        </div>

                        <OurServices/>

                    </div>
                    <div className="wrap-link" style={{marginBottom: 30}}>
                        <NavLink to="/contact" exact="true">
                            {width > 500 ?
                                <InView triggerOnce>
                                    {({inView, ref}) => (
                                        <motion.div
                                            className="contact-with-us"
                                            variants={animateVariants.text}
                                            initial="hidden"
                                            animate={inView ? "show" : "hidden"}
                                            exit="exit"
                                            ref={ref}
                                        ><h4>work with us</h4>
                                        </motion.div>
                                    )}
                                </InView>
                                :
                                <div
                                    className="contact-with-us">
                                    <h4>work with us</h4>
                                </div>
                            }

                        </NavLink>
                    </div>

                </section>

                <section className="about">
                    <h2 className="about_title">High-quality web development of websites in Vilvorde</h2>

                    <div className="about_content">
                        <SimpleBar style={{maxHeight: 500}}>
                            {width > 500 ?
                                <InView triggerOnce>
                                    {({inView, ref}) => (
                                        <motion.div
                                            variants={animateVariants.divider}
                                            initial="hidden"
                                            animate={inView ? "show" : "hidden"}
                                            exit="exit"
                                            ref={ref}
                                        >
                                            <p>High-quality web development of websites in Brussels is a prerequisite
                                                for
                                                creating
                                                an effective tool, because the website is one of the most important
                                                elements
                                                of
                                                business success in the digital era. That is why it is important that
                                                development and promotion of websites should be carried out by
                                                professionals.
                                                The opportunities offered by website creation and development have a
                                                very
                                                positive effect on the growth of business projects of any complexity.
                                                This
                                                tool
                                                is capable of:</p>
                                            <ul>
                                                <li>provide beneficial introduction of company to potential client;</li>
                                                <li>present all the advantages of products and services;</li>
                                                <li>prepare the client for direct contact and purchase;</li>
                                                <li>make a sale;</li>
                                                <li>become the base of clients’ trust and loyalty;</li>
                                                <li>provide an opportunity to qualitatively analyze the audience and its
                                                    behavior in order to adjust strategies of the business and its
                                                    further
                                                    development.
                                                </li>
                                                <li>The main mission of devColibri team is to develop and create
                                                    effective,
                                                    progressive and user-friendly websites.
                                                </li>
                                            </ul>
                                        </motion.div>
                                    )}
                                </InView>
                                :
                                <div>
                                    <p>High-quality web development of websites in Brussels is a prerequisite for
                                        creating
                                        an effective tool, because the website is one of the most important elements
                                        of
                                        business success in the digital era. That is why it is important that
                                        development and promotion of websites should be carried out by
                                        professionals.
                                        The opportunities offered by website creation and development have a very
                                        positive effect on the growth of business projects of any complexity. This
                                        tool
                                        is capable of:</p>
                                    <ul>
                                        <li>provide beneficial introduction of company to potential client;</li>
                                        <li>present all the advantages of products and services;</li>
                                        <li>prepare the client for direct contact and purchase;</li>
                                        <li>make a sale;</li>
                                        <li>become the base of clients’ trust and loyalty;</li>
                                        <li>provide an opportunity to qualitatively analyze the audience and its
                                            behavior in order to adjust strategies of the business and its further
                                            development.
                                        </li>
                                        <li>The main mission of devColibri team is to develop and create effective,
                                            progressive and user-friendly websites.
                                        </li>
                                    </ul>
                                </div>
                            }

                            {width > 500 ?
                                <InView triggerOnce>
                                    {({inView, ref}) => (
                                        <motion.div
                                            variants={animateVariants.divider}
                                            initial="hidden"
                                            animate={inView ? "show" : "hidden"}
                                            exit="exit"
                                            ref={ref}
                                        >
                                            <p>Developing an efficient and vibrant website.
                                                To develop an efficient and vibrant website in Brussels, it is not
                                                enough to
                                                simply
                                                find
                                                good contractors. This is a process of close cooperation between a
                                                client
                                                company and a
                                                studio. For a company to be ready for this website creation process, it
                                                is
                                                important
                                                that it clearly knows the following:</p>
                                            <ul>
                                                <li>1. Website is created for end user, not for team or
                                                    management.Starting
                                                    website
                                                    building, the team from the client’s part must know well their
                                                    target
                                                    audience, its
                                                    behavior, needs, pains, opportunities and ways to implement these
                                                    opportunities in
                                                    order to stand out among the competitors. This will create a product
                                                    that
                                                    will
                                                    compare favorably with the general market.
                                                </li>
                                                <li>2. Work on the website is a ‘tandem jump’.Only understanding the
                                                    potentials
                                                    of the
                                                    company’s product, its main features, you will be able to convey
                                                    them to
                                                    the
                                                    user.
                                                    In order for the web studio to uncover the main accents most
                                                    profitably,
                                                    communication needs to be given a sufficiently large amount of time;
                                                    only
                                                    active
                                                    communication will help create an efficient website.
                                                </li>
                                                <li>3. Trust and discuss is a must.The customer is responsible for the
                                                    product
                                                    and
                                                    service for which the website is built. He is not obliged to know
                                                    all
                                                    the
                                                    subtleties
                                                    and possibilities of implementing such a tool as a website. The task
                                                    of
                                                    the
                                                    web
                                                    studio is to develop the website, it should offer the most optimal
                                                    ways
                                                    to
                                                    implement
                                                    the project. Building a website, discuss necessary functionality
                                                    previously
                                                    and
                                                    trust the studio in choosing the path.
                                                </li>
                                            </ul>
                                        </motion.div>
                                    )}
                                </InView>
                                :
                                <div>
                                    <p>Developing an efficient and vibrant website.
                                        To develop an efficient and vibrant website in Brussels, it is not enough to
                                        simply
                                        find
                                        good contractors. This is a process of close cooperation between a client
                                        company and a
                                        studio. For a company to be ready for this website creation process, it is
                                        important
                                        that it clearly knows the following:</p>
                                    <ul>
                                        <li>1. Website is created for end user, not for team or management.Starting
                                            website
                                            building, the team from the client’s part must know well their target
                                            audience, its
                                            behavior, needs, pains, opportunities and ways to implement these
                                            opportunities in
                                            order to stand out among the competitors. This will create a product
                                            that
                                            will
                                            compare favorably with the general market.
                                        </li>
                                        <li>2. Work on the website is a ‘tandem jump’.Only understanding the
                                            potentials
                                            of the
                                            company’s product, its main features, you will be able to convey them to
                                            the
                                            user.
                                            In order for the web studio to uncover the main accents most profitably,
                                            communication needs to be given a sufficiently large amount of time;
                                            only
                                            active
                                            communication will help create an efficient website.
                                        </li>
                                        <li>3. Trust and discuss is a must.The customer is responsible for the
                                            product
                                            and
                                            service for which the website is built. He is not obliged to know all
                                            the
                                            subtleties
                                            and possibilities of implementing such a tool as a website. The task of
                                            the
                                            web
                                            studio is to develop the website, it should offer the most optimal ways
                                            to
                                            implement
                                            the project. Building a website, discuss necessary functionality
                                            previously
                                            and
                                            trust the studio in choosing the path.
                                        </li>
                                    </ul>
                                </div>
                            }

                            {width > 500 ?
                                <InView triggerOnce>
                                    {({inView, ref}) => (
                                        <motion.div
                                            variants={animateVariants.divider}
                                            initial="hidden"
                                            animate={inView ? "show" : "hidden"}
                                            exit="exit"
                                            ref={ref}
                                        >
                                            <p>What types of websites can be ordered from devColibri?</p>
                                            <p>We work in Belgium to build websites of varying complexity:</p>
                                            <ul>
                                                <li>landing page;</li>
                                                <li>promo websites;</li>
                                                <li>corporate websites;</li>
                                                <li>online catalogs;</li>
                                                <li>online stores;</li>
                                                <li>web services.</li>
                                            </ul>
                                        </motion.div>
                                    )}
                                </InView>
                                :
                                <div>
                                    <p>What types of websites can be ordered from devColibri?</p>
                                    <p>We work in Belgium to build websites of varying complexity:</p>
                                    <ul>
                                        <li>landing page;</li>
                                        <li>promo websites;</li>
                                        <li>corporate websites;</li>
                                        <li>online catalogs;</li>
                                        <li>online stores;</li>
                                        <li>web services.</li>
                                    </ul>
                                </div>
                            }

                            {width > 500 ?
                                <InView triggerOnce>
                                    {({inView, ref}) => (
                                        <motion.div
                                            variants={animateVariants.divider}
                                            initial="hidden"
                                            animate={inView ? "show" : "hidden"}
                                            exit="exit"
                                            ref={ref}
                                        >
                                            <p>Each of these website types requires a detailed study of the goals and
                                                objectives
                                                of our
                                                client, because we pay great attention to communication and analysis of
                                                his
                                                needs.</p>
                                            <p>What is the process of building websites in the devColibri studio?
                                                We are absolutely open, we build trust with the client, because we
                                                consider
                                                this
                                                to be the
                                                key to the success of the final product.</p>
                                            <p>So that you understand how the joint work on the website will take place,
                                                we
                                                divide the
                                                process into stages:</p>
                                            <ul>
                                                <li>1. Request and first communication – the stage is necessary for an
                                                    open
                                                    dialogue to
                                                    indicate general picture of problems and goals of the client. Our
                                                    manager,
                                                    in a
                                                    convenient format for you, meets with key persons of the company who
                                                    will
                                                    participate in
                                                    the website development.
                                                </li>
                                                <li>2. Filling in the brief is an important stage at which the client
                                                    fills
                                                    in a
                                                    detailed
                                                    brief, which in a concise manner gives our team the opportunity to
                                                    find
                                                    out
                                                    the pain
                                                    points of the client’s company, the strategy of its movement.
                                                </li>
                                                <li>3. Development of a prototype – the stage at which the skeleton of
                                                    the
                                                    future website is
                                                    built, which determines which blocks will be basic and which will be
                                                    minor.
                                                    Information
                                                    and tools are defined: calls to action, forms, places of contact and
                                                    so
                                                    on.
                                                    This stage
                                                    builds the logic of the relationship of all elements of the website,
                                                    forms
                                                    the flow map
                                                    of the end user.
                                                </li>
                                                <li>4. Development of statement of work – a document that contains a
                                                    description
                                                    of all
                                                    blocks of the approved prototype and technical functions of the
                                                    future
                                                    website.
                                                </li>
                                                <li>5. Design – the stage is divided and has two intervals:
                                                    development and approval of the design of the home page, which
                                                    becomes
                                                    the
                                                    base, the
                                                    basic concept of all further stylistics;
                                                </li>
                                                <li>6. Layout and programming – working with the technical side of the
                                                    website
                                                    and its
                                                    functionality.
                                                </li>
                                                <li>7. Testing – providing the client with a test link for initial
                                                    review
                                                    with
                                                    already alive
                                                    website and its final approval.
                                                </li>
                                                <li>8. Transfer for hosting and delivery of the project – the final
                                                    stage,
                                                    which
                                                    involves
                                                    transfer of the website to the client for use, familiarization with
                                                    administration and
                                                    support processes.
                                                    When ordering website building from devColibri web studio, the
                                                    client is
                                                    calm and
                                                    protected, because from the first day a formal cooperation agreement
                                                    is
                                                    signed, in which
                                                    all the stages, deadlines, costs, points of responsibility of the
                                                    parties
                                                    are fixed.
                                                </li>
                                            </ul>
                                        </motion.div>
                                    )}
                                </InView>
                                :
                                <div>
                                    <p>Each of these website types requires a detailed study of the goals and
                                        objectives
                                        of our
                                        client, because we pay great attention to communication and analysis of his
                                        needs.</p>
                                    <p>What is the process of building websites in the devColibri studio?
                                        We are absolutely open, we build trust with the client, because we consider
                                        this
                                        to be the
                                        key to the success of the final product.</p>
                                    <p>So that you understand how the joint work on the website will take place, we
                                        divide the
                                        process into stages:</p>
                                    <ul>
                                        <li>1. Request and first communication – the stage is necessary for an open
                                            dialogue to
                                            indicate general picture of problems and goals of the client. Our
                                            manager,
                                            in a
                                            convenient format for you, meets with key persons of the company who
                                            will
                                            participate in
                                            the website development.
                                        </li>
                                        <li>2. Filling in the brief is an important stage at which the client fills
                                            in a
                                            detailed
                                            brief, which in a concise manner gives our team the opportunity to find
                                            out
                                            the pain
                                            points of the client’s company, the strategy of its movement.
                                        </li>
                                        <li>3. Development of a prototype – the stage at which the skeleton of the
                                            future website is
                                            built, which determines which blocks will be basic and which will be
                                            minor.
                                            Information
                                            and tools are defined: calls to action, forms, places of contact and so
                                            on.
                                            This stage
                                            builds the logic of the relationship of all elements of the website,
                                            forms
                                            the flow map
                                            of the end user.
                                        </li>
                                        <li>4. Development of statement of work – a document that contains a
                                            description
                                            of all
                                            blocks of the approved prototype and technical functions of the future
                                            website.
                                        </li>
                                        <li>5. Design – the stage is divided and has two intervals:
                                            development and approval of the design of the home page, which becomes
                                            the
                                            base, the
                                            basic concept of all further stylistics;
                                        </li>
                                        <li>6. Layout and programming – working with the technical side of the
                                            website
                                            and its
                                            functionality.
                                        </li>
                                        <li>7. Testing – providing the client with a test link for initial review
                                            with
                                            already alive
                                            website and its final approval.
                                        </li>
                                        <li>8. Transfer for hosting and delivery of the project – the final stage,
                                            which
                                            involves
                                            transfer of the website to the client for use, familiarization with
                                            administration and
                                            support processes.
                                            When ordering website building from devColibri web studio, the client is
                                            calm and
                                            protected, because from the first day a formal cooperation agreement is
                                            signed, in which
                                            all the stages, deadlines, costs, points of responsibility of the
                                            parties
                                            are fixed.
                                        </li>
                                    </ul>
                                </div>
                            }

                            {width > 500 ?
                                <InView triggerOnce>
                                    {({inView, ref}) => (
                                        <motion.div
                                            variants={animateVariants.divider}
                                            initial="hidden"
                                            animate={inView ? "show" : "hidden"}
                                            exit="exit"
                                            ref={ref}
                                        >
                                            <p>What else makes collaboration with devColibri web studio beneficial?
                                                Brussels is a city with a lot of web studios, we understand that
                                                choosing a
                                                contractor has
                                                many factors influencing the decision to cooperate. devColibri website
                                                development studio
                                                works for the client’s result, fulfills its obligations with the highest
                                                quality.</p>
                                            <p>In order to satisfy more complete needs of companies in digital
                                                environment,
                                                in addition to
                                                developing websites in Brusels and Belgium, we have included development
                                                of
                                                logos, mobile
                                                applications, improvement, promotion and support of websites in our list
                                                of
                                                our
                                                services.</p>
                                            <p>Thus, choosing devColibri, the client gets a partner in Brussels for
                                                implementation of the most
                                                important element of his digital strategy. Since each of the listed
                                                services
                                                affects the
                                                choice of the end user, we work on the complex so that each of them
                                                emphasizes and carried
                                                unique selling point of our client.</p>
                                            <p>To make sure that devColibri team is made up of professionals, check out
                                                our
                                                portfolio and
                                                feedback of existing clients.</p>
                                        </motion.div>
                                    )}
                                </InView>
                                :
                                <div>
                                    <p>What else makes collaboration with devColibri web studio beneficial?
                                        Brussels is a city with a lot of web studios, we understand that choosing a
                                        contractor has
                                        many factors influencing the decision to cooperate. devColibri website
                                        development studio
                                        works for the client’s result, fulfills its obligations with the highest
                                        quality.</p>
                                    <p>In order to satisfy more complete needs of companies in digital environment,
                                        in addition to
                                        developing websites in Brusels and Belgium, we have included development of
                                        logos, mobile
                                        applications, improvement, promotion and support of websites in our list of
                                        our
                                        services.</p>
                                    <p>Thus, choosing devColibri, the client gets a partner in Brussels for
                                        implementation of the most
                                        important element of his digital strategy. Since each of the listed services
                                        affects the
                                        choice of the end user, we work on the complex so that each of them
                                        emphasizes and carried
                                        unique selling point of our client.</p>
                                    <p>To make sure that devColibri team is made up of professionals, check out our
                                        portfolio and
                                        feedback of existing clients.</p>
                                </div>
                            }

                        </SimpleBar>
                    </div>


                </section>

                <section className="about-you" style={{textAlign: 'center'}}>
                    <p>Enough about us, tell us about yourself!</p>
                </section>

            </Layout>
            <Footer/>
        </>
    );
};

export default Home;