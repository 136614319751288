import React, {useEffect, useState} from 'react';
import Layout from "../../components/layout/layout";
import {NavLink, useLocation} from "react-router-dom";
import {InView} from "react-intersection-observer";
import {motion} from "framer-motion";
import {animateVariants} from "../../components/animateVariants";
import arrow from '../../img/arrow-east.svg'
import StartProject from "../../components/startProject";
import OurServices from "../../components/our-services/our-services";
import design from "../../img/services/design.png";
import useWindowSize from "../../utils/useWindowSize";
import SimpleBar from "simplebar-react";
import Footer from "../../components/footer/footer";
import img from "../../img/services/store.png";
import img_2 from "../../img/services/catalog.png";
import img_3 from "../../img/services/corporate.png";
import img_4 from "../../img/services/service.png";
import img_5 from "../../img/services/landing.png";
import img_6 from "../../img/services/promo.png";

const Services = () => {
    const contents = [
        {
            img: img,
            href: '/service/development/online-store',
            title: 'Online store',
            content: 'Online store is an automated web resource designed to sell goods online. You do not need to open additional points of sales throughout a city or a country. It is enough to have a high-quality, functional and well-built online store.a'
        },
        {
            img: img_2,
            href: '/service/development/online-catalog',
            title: 'Online catalog',
            content: 'Online catalog is an electronic showcase of your products or services. This type of website is intended for spectacular display of content on your resource.'
        },
        {
            img: img_3,
            href: '/service/development/corporate-website',
            title: 'Corporate website',
            content: 'Corporate website is representation of your company on the internet. This type of website reveals the essence of the company, forms your image and brand.'
        },
        {
            img: img_4,
            href: '/service/development/web-service',
            title: 'Web service',
            content: 'Web service is a large-scale online project with a complex structure and navigation. This type of development is intended for non-standard tasks, a large number of visitors and popularity of use.'
        },
        {
            img: img_5,
            href: '/service/development/landing-page',
            title: 'Landing page',
            content: 'Landing page is a landing page with a call to action, display of a product or a service. The main task of building a website is commission of a target action by client.'
        },
        {
            img: img_6,
            href: '/service/development/promo-website',
            title: 'Promo website',
            content: 'Promo website is your business card on the internet, the main purpose of which is brief transfer of information about company or person.'
        }
    ]
    const CorporateWebsite = [
        {
            title: 'Business',
            description: ['Market analysis', 'Development of project prototypes', 'Customized design', 'Mobile Website Home Page Design', 'Basic SEO optimization*', 'Corporate mail configuration*'],
            content: ['Administrator website management', 'Product/service overview', 'Interactive map tagged', 'Photo gallery', 'Video gallery', 'Customized marketing tools', 'Share products/articles via social networks*', 'Capture forms', 'Multilanguage', 'Subscription system', 'Integration of news feeds/posts via social networks*', 'Connecting Google Analytics']
        },
        {
            title: 'Custom',
            description: ['Market analysis', 'Development of project prototypes', 'Premium design with a complex structure', 'Elements of Graphics Design', 'Animation and WOW effects*', '3D graphics/360*', 'Unique design of mobile version', 'Basic SEO optimization*', 'Corporate mail configuration*'],
            content: ['Administrator website management', 'Product/service overview', 'Interactive map tagged', 'Photo gallery', 'Video gallery', 'Customized marketing tools', 'Sales triggers', 'Synchronization with CRM systems*', 'Share products/articles via social networks*', 'Capture forms', 'Multilanguage', 'Subscription system', 'Integration of news feeds/posts via social networks*', 'Connecting Google Analytics', 'Turnkey website content filling*']
        },
        {
            title: 'WOW',
            description: ['Want something new and unusual?', 'Wish to stand out?', 'Wanna be at the top?', 'Dream to conquer space?'],
            content: ['Then click!']
        }
    ]
    const location = useLocation();
    const {width} = useWindowSize()
    console.log(width)
    const [scrollPosition, setScrollPosition] = useState(50);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const getToPrice = () => {
        console.log('price')
    }
    let services = () => {
        let services = 'services'
    };
    return (
        <>
            <Layout>
                <section className="bread-crumbs">
                    <NavLink to="/">home</NavLink>
                    {location.pathname !== "/service" &&<><span> /</span><NavLink className="link-right" to="/service">service</NavLink></>}

                </section>
                <section className="service">
                    {location.pathname === '/service/design' &&
                    <section className="design">
                        <div className="corporate-website_top">
                            <div className="corporate-website_top_left">
                                <h1>Design</h1>
                                <span>CREATIVE IDEAS + EFFICIENT MARKETING!</span>
                                <p>Website design is not just an external design of your resource, first of all it is
                                    convenience of
                                    using website.</p>
                                <p>Appearance primarily depends on experience and talent of a designer, ability to
                                    correctly
                                    assemble elements and use marketing tools in prototypes and design.</p>
                                <p>Main task of the designer, in addition to ingenuity of the work, is competent
                                    marketing
                                    planning,
                                    maximum retention of client with commission of target action.</p>
                            </div>

                            <div className="our-services_content-img">
                                <img src={design}
                                     className={scrollPosition > 80 && scrollPosition < 1800 && width > 800 ? "slip corporate-website" : "corporate-website"}
                                     alt="designWeb"/>
                            </div>
                        </div>

                        <StartProject/>

                        <div className="tariff-plans_items">
                            {CorporateWebsite.map(c => (
                                <div className="tariff-plans_item corporate-website" key={c.title}>
                                    <SimpleBar style={{maxHeight: 500}}>
                                        <div className="tariff-top">
                                            <h3>{c.title}</h3>
                                            {c.description.map(i => (
                                                <><span key={i}>{i}</span><br/></>
                                            ))}
                                        </div>
                                        <div className="tariff-middle">
                                            <span style={{marginLeft: 20}}>Functional component:</span>
                                            <ul>
                                                {c.content.map(i => (
                                                    <li key={i}>{i}</li>
                                                ))}
                                            </ul>
                                        </div>

                                    </SimpleBar>
                                    <div className="tariff-bottom">
                                        <button onClick={getToPrice}>Find out the price</button>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </section>
                    }
                    {location.pathname === '/service/development' &&
                    <section className="web-development">
                        <h1>Web development</h1>
                        <span>Craft, fast and functional websites</span>
                        <p>Our team develops and creates websites of any complexity. We will help you to realize your
                            idea
                            and turn it into a modern web product.</p>
                        <div className="web-development_items">

                            {contents.map(c => {
                                if (width > 500){
                                    return (
                                        <InView triggerOnce key={c.href}>
                                            {({inView, ref}) => (
                                                <motion.div
                                                    className="web-development_item"
                                                    variants={animateVariants.divider}
                                                    initial="hidden"
                                                    animate={inView ? "show" : "hidden"}
                                                    exit="exit"
                                                    ref={ref}

                                                >
                                                    <NavLink to={c.href}>
                                                        <div className="web-development_item_title">
                                                            <span>{c.title}</span>
                                                            <img src={arrow} alt="east"/>
                                                        </div>
                                                        <div className="web-development_item_content">
                                                            <img src={c.img} alt="east"/>
                                                            <p>{c.content}</p>
                                                        </div>
                                                    </NavLink>
                                                </motion.div>
                                            )}
                                        </InView>
                                    )
                                } else {
                                    return (

                                                <div
                                                    className="web-development_item">
                                                    <NavLink to={c.href}>
                                                        <div className="web-development_item_title">
                                                            <span>{c.title}</span>
                                                            <img src={arrow} alt="east"/>
                                                        </div>
                                                        <div className="web-development_item_content">
                                                            <img src={c.img} alt="east"/>
                                                            <p>{c.content}</p>
                                                        </div>
                                                    </NavLink>
                                                </div>

                                    )
                                }
                            }

                            )}

                        </div>
                    </section>
                    }
                    {location.pathname === '/service/seo' &&
                    <h1>sample text seo</h1>
                    }
                    {location.pathname === '/service' &&
                    <>
                        <OurServices servuces={'services'}/>
                    </>
                    }
                </section>
            </Layout>
            <Footer/>
        </>
    );
};

export default Services;