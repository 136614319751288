import React from 'react';
import {NavLink} from "react-router-dom";
import {InView} from "react-intersection-observer";
import {motion} from "framer-motion";
import {animateVariants} from "./animateVariants";

const StartProject = () => {
    return (
        <div className="start-project">
                            <span className="start-project_title">Let's interact! Apart from getting
                                cool experience, we will prove
                                that IT is easy :)</span>
            <NavLink to="/contact" exact="true">
                <InView>
                    {({inView, ref}) => (
                        <motion.div
                            className="contact-with-us"
                            variants={animateVariants.text}
                            initial="hidden"
                            animate={inView ? "show" : "hidden"}
                            exit="exit"
                            ref={ref}
                        ><h4>project start</h4>
                        </motion.div>
                    )}
                </InView>
            </NavLink>
        </div>
    );
};

export default StartProject;