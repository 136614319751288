import actionType from "./type";

const initialState = {
    cursorHover: false,
};

const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.setGlobalCursorHover:
            return {
                ...state,
                cursorHover: action.value,
            };

        case actionType.setGlobalBgColor:
            return {
                ...state,
                bgColor: action.value,
            };

        default:
            return {
                ...state,
            };
    }
};

export default globalReducer;