import {CHANGE_MODAL, CLOSE_MODAL} from './type'


export const changeModal = () => dispatch => {
    dispatch({
        type: CHANGE_MODAL
    })
}

export const closeModal = () => dispatch => {
    dispatch({
        type: CLOSE_MODAL
    })
}
