import React from 'react';
import Layout from "../components/layout/layout";

const NotFound = () => {
    return (
        <Layout>
            <h1>NotFound</h1>
        </Layout>
    );
};

export default NotFound;