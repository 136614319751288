import React, {useCallback, useEffect, useState} from "react";
import {motion} from "framer-motion";
import useWindowSize from "../utils/useWindowSize";
import {InView} from "react-intersection-observer";
import {animateVariants} from "./animateVariants";
import arrow from "../img/arrow-east.svg";


const ScrollToTop = () => {

    const {width} = useWindowSize()
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    // console.log('scrollPosition is:', scrollPosition)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    if (width > 500) {
        return (
            <>
                {scrollPosition > 100 &&
                <InView>
                    {({inView, ref}) => (
                        <motion.div className="scroll-to-top"
                                    onClick={handleScrollToTop}
                                    variants={animateVariants.divider}
                                    initial="hidden"
                                    animate={inView ? "show" : "hidden"}
                                    exit="exit"
                                    ref={ref}
                        >
                            <img src={arrow} alt="east"/>
                        </motion.div>
                    )}
                </InView>
                }
            </>

        );
    } else return (
            <>
                {scrollPosition > 100 &&
                <div className="scroll-to-top" onClick={handleScrollToTop}>
                    <img src={arrow} alt="east"/>
                </div>
                }
            </>
            )
};

            export default ScrollToTop;