import React, {useEffect, useState} from "react";
import {useLocation, NavLink} from "react-router-dom";
import {motion} from "framer-motion";
import {useDispatch} from "react-redux";
import {changeModal} from "../../redux/modal/modalAction";
import {InView} from "react-intersection-observer";
import {animateVariants} from "../animateVariants";

export const NavItemModal = ({title, href, child}) => {
    const [isShown, setIsShown] = useState(false);
    const location = useLocation();
    const [width, setWidth] = useState(10);

    useEffect(() => {
        if (location.pathname === href) {
            setWidth(25);
        } else {
            setWidth(10);
        }
    }, [setWidth, location, href]);

    const handleCursorHover = (value) => {
        if (location.pathname !== href || href !== '/service') {
            if (value) {
                setWidth(20);
            } else {
                setWidth(10);
            }
        }
    };

    const dispatch = useDispatch();
    const toggleSidebar = () => {
        dispatch(changeModal())
    }
    return (
        <>
            {child ?
                <div
                    className={!isShown ? "nav-link_modal" : "nav-link_modal child"}
                    onMouseEnter={() => setIsShown(true)}
                    onMouseLeave={() => setIsShown(false)}
                >
                    <span className="services_span">{title}</span>

                    {child && isShown && (

                            <InView>
                                {({inView, ref}) => (
                            <motion.div className="dropdown-link"
                                 variants={animateVariants.text}
                                 initial="hidden"
                                 animate={inView ? "show" : "hidden"}
                                 exit="exit"
                                 ref={ref}
                            >
                                <NavLink to={`${href}/${child.design.title}`} exact="true">
                                    <p onClick={() => toggleSidebar()}>{child.design.title}</p>
                                </NavLink>
                                <NavLink to={`${href}/${child.development.title}`} description={child.development.description} exact="true">
                                    <p onClick={() => toggleSidebar()}>{child.development.title}</p>
                                </NavLink>
                                <NavLink to={`${href}/${child.seo.title}`} description={child.seo.description} exact="true">
                                    <p onClick={() => toggleSidebar()}>{child.seo.title}</p>
                                </NavLink>
                            </motion.div>
                                )}
                            </InView>

                    )}
                </div>
                :
                <NavLink to={href} exact="true">
                    <motion.div
                        onHoverStart={() => handleCursorHover(true)}
                        onHoverEnd={() => handleCursorHover(false)}
                        className="nav-link_modal"
                    >
                <span onClick={() => dispatch(changeModal())}

                >{title}</span>

                    </motion.div>

                </NavLink>
            }

        </>
    );
};