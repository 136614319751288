import {Routes, Route, useLocation} from 'react-router-dom';
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import Services from "./pages/services/Services";
import Portfolio from "./pages/Portfolio";
import PortfolioProject from "./pages/PortfolioProject";
import ServicesLinks from "./pages/services/ServicesLinks";
import {useLayoutEffect} from 'react';
import useWindowSize from "./utils/useWindowSize";
import Cursor from "./components/Cursor";

const App = () => {
    const Wrapper = ({children}) => {
        const location = useLocation();
        useLayoutEffect(() => {
            document.documentElement.scrollTo(0, 0);
        }, [location.pathname]);
        return children
    }

    const {width} = useWindowSize()

    return (

            <Wrapper>
                {/*{width >= 500 && <Cursor />}*/}
                <Routes>

                    <Route exact path="/" element={<Home/>}/>
                    <Route exact path="/about" element={<About/>}/>
                    <Route exact path="/contact" element={<Contact/>}/>
                    <Route exact path="/service" element={<Services/>}/>
                    <Route exact path="/service/:slug" element={<Services/>}/>
                    <Route exact path="/service/:slug/:slug" element={<ServicesLinks/>}/>
                    <Route exact path="/portfolio" element={<Portfolio/>}/>
                    <Route exact path="/portfolio/:slug" element={<PortfolioProject/>}/>

                    <Route exact path="/*" element={<NotFound/>}/>

                </Routes>
            </Wrapper>

    );
};

export default App;
