import {
    DESIGN_SERVICES,
    DEVELOPMENT_SERVICES,
    SEO_SERVICES} from './type'

const initialState = {
    design: 'sample text design',
    development: 'sample text development',
    seo: 'sample text seo',
}

export default function servicesReducer(state = initialState, action){
    switch (action.type){
        case  DESIGN_SERVICES:
            return {
                ...state,
                design: state.design,
            }
        case  DEVELOPMENT_SERVICES:
            return {
                ...state,
                development: state.development,
            }
        case  SEO_SERVICES:
            return {
                ...state,
                seo: state.seo,
            }
        default:
            return state
    }
}