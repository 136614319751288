import React from 'react';
import Layout from "../components/layout/layout";
import {NavLink, useLocation} from "react-router-dom";
import data from '../data/data.json'
import {InView} from "react-intersection-observer";
import {animateVariants} from "../components/animateVariants";
import {motion} from "framer-motion";
import arrow from '../img/arrow-east.svg'
import useWindowSize from "../utils/useWindowSize";

const PortfolioProject = (props) => {
    const {width} = useWindowSize()
    const location = useLocation()
    let path = location.pathname.split('/')
    let pat = path[path.length - 1]
    // console.log(pat)
    data.projects.map((i) => {
        if (i.slug === pat) {
            console.log('data.url: ', i.url)
        }
    })
    return (
        <Layout>
            <section className="bread-crumbs">
                <NavLink to="/">home</NavLink><span> /</span>
                <NavLink className="link-right" to="/portfolio">portfolio</NavLink>
            </section>
            <section className="portfolio-project">
                {data.projects.map((i) => {
                    if (i.slug === pat) {
                        if (width > 500) {
                            return (
                                <>
                                    <InView triggerOnce>
                                        {({inView, ref}) => (
                                            <motion.div className="portfolio-item"
                                                        variants={animateVariants.divider}
                                                        initial="hidden"
                                                        animate={inView ? "show" : "hidden"}
                                                        exit="exit"
                                                        ref={ref}
                                                // style={{background:'#8d7b7b'}}
                                                // style={{
                                                //     backgroundImage: `linear-gradient(${i.bannerColor.primary}, ${i.bannerColor.secondary})`,
                                                // }}
                                            >
                                                <h1>{i.name}</h1>

                                                <img src={i.details.imageDesctop} alt=""/>
                                                <p>{i.details.description}</p>
                                            </motion.div>
                                        )}
                                    </InView>
                                    <div className="portfolio-link">
                                        <a href={i.url} target="_blank">
                                            <img className="image-link" src={arrow} alt="link"/></a>
                                    </div>
                                </>
                            )
                        } else {
                            return (
                                <>
                                    <div className="portfolio-item">
                                        <h1>{i.name}</h1>

                                        <img src={i.details.imageDesctop} alt=""/>
                                        <p>{i.details.description}</p>
                                    </div>

                                    <div className="portfolio-link">
                                        <a href={i.url} target="_blank">
                                            <img className="image-link" src={arrow} alt="link"/></a>
                                    </div>
                                </>
                            )
                        }

                    }
                })}
            </section>
        </Layout>
    );
};

export default PortfolioProject;