import React from 'react';
import ReactDOM from "react-dom";
import App from './App';
import {Provider} from "react-redux";
import store from "./redux/store";
import 'antd/dist/antd.min.css'
import './sass/index.scss';
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>,
    document.getElementById("root")
);

