import React from 'react';
import {InView} from "react-intersection-observer";
import {motion} from "framer-motion";
import {animateVariants, headerVariants_2} from "../animateVariants";
import {NavLink, useLocation} from "react-router-dom";
import arrow from "../../img/arrow-east.svg";
import img from "../../img/services/design.png";
import img_1 from "../../img/services/development_2.png";
import img_3 from "../../img/services/seo.png";
import useWindowSize from "../../utils/useWindowSize";

const OurServices = () => {
    const location = useLocation();
    const {width} = useWindowSize()
    const contents = [
        {
            img: img,
            href: '/service/design',
            title: 'Design',
            content: 'Website design is not just an external design of your resource. First of all, it is Web usability. The main task of the designer, besides the originality of the work, is competent marketing planning, maximum customer retention, and desired action.'
        },
        {
            img: img_1,
            href: '/service/development',
            title: 'Development',
            content: 'We create websites of any complexity. We help to fulfill your wishes, meet your needs: from the simplest to individual and non-standard ideas.'
        },
        {
            img: img_3,
            href: '/service/seo',
            title: 'Seo',
            content: 'SEO stands for “search engine optimization.” In simple terms, it means the process of improving your site to increase its visibility when people search for products or services related to your business in Google, Bing, and other search engines.'
        }
    ]
    if (width > 500) {
        return (
            <div
                className={location.pathname === '/service' ? "our-services_content-items service" : "our-services_content-items"}>
                {contents.map((content) => (
                    // <InView triggerOnce>
                    <InView triggerOnce key={content.href}>
                        {({inView, ref}) => (
                            <motion.div className="our-services_content-item"
                                        variants={headerVariants_2}
                                        initial="hidden"
                                        animate={inView ? "show" : "hidden"}
                                        exit="exit"
                                        ref={ref}
                                // key={content.href}
                            >
                                <NavLink to={content.href} exact="true">
                                    <motion.div className="our-services_content-item-header"
                                                variants={animateVariants.divider}
                                                initial="hidden"
                                                animate={inView ? "show" : "hidden"}
                                                exit="exit"
                                                ref={ref}
                                    >
                                        <h3>{content.title}</h3>
                                        <img src={arrow} alt="east"/>

                                    </motion.div>

                                    <div className="our-services_content-item-content">
                                        <img src={content.img} alt="east"/>
                                        <p>{content.content}</p>
                                    </div>
                                </NavLink>
                            </motion.div>
                        )}
                    </InView>
                ))}
            </div>
        );
    } else {
        return (
            <div
                className={location.pathname === '/service' ? "our-services_content-items service" : "our-services_content-items"}>
                {contents.map((content) => (
                    <div className="our-services_content-item">
                        <NavLink to={content.href} exact="true">
                            <div className="our-services_content-item-header">
                                <h3>{content.title}</h3>
                                <img src={arrow} alt="east"/>
                            </div>
                            <div className="our-services_content-item-content">
                                <img src={content.img} alt="east"/>
                                <p>{content.content}</p>
                            </div>
                        </NavLink>
                    </div>
                ))}
            </div>
        )
    }

};

export default OurServices;