import React, {useCallback, useEffect, useState} from 'react'
import {NavLink} from 'react-router-dom'
import {motion} from 'framer-motion'
import {useDispatch, useSelector} from 'react-redux'
import {changeModal, closeModal} from '../../redux/modal/modalAction'
import {animateVariants, headerVariants_2} from '../animateVariants'
import {links} from './links'
import {NavItemModal} from './NavItemModal'

import logo from '../../img/logo-devcolibri-23.webp'
import useWindowSize from "../../utils/useWindowSize";

const Header = () => {
    const dispatch = useDispatch()
    let modalBar = useSelector((state) => state.modal.modalBar)
    const {width} = useWindowSize()
    const toggleSidebar = () => {
        dispatch(changeModal())
    }

    const [scroll, setScroll] = useState(false)
    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScroll(window.scrollY > 150)
        })
    }, [scroll])

    const closeSidebar = () => {
        console.log('close')
    }

    if (!modalBar) {
        document.body.classList.add('modal')
    } else {
        document.body.classList.remove('modal')
    }


    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            console.log("esc pressed");
            dispatch(changeModal())
        }
    }, []);

    useEffect(() => {
        if (width > 500) {
            document.addEventListener("keydown", escFunction);

            return () => {
                document.removeEventListener("keydown", escFunction);
            };
        }

    }, [escFunction, width]);

    return (
        <>
            <div className='header'>
                <div className={scroll ? 'container-header background' : 'container-header'}>
                    <div className='nav-header'>
                        <div className='nav-header_left'>
                            <div className='burger-left'>
                                <motion.div className='sidebar-toggle' onClick={toggleSidebar}>
                                    <div className={!modalBar ? 'open' : ''} id='sidebar-toggle'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </motion.div>
                            </div>

                            <div className='nav-header_logo'>
                                <NavLink to='/'>
                                    <img src={logo} alt='logo'/>
                                </NavLink>
                            </div>
                        </div>

                        <div className='nav-header_right'>
                            <div className='nav'>
                                <a href='tel: +32 492 52 91 43' className='nav-item'>
                                    +32 492 52 91 43
                                </a>
                                <a href='mailto: info@devColibri.biz' className='nav-item'>
                                    info@devColibri.biz
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {!modalBar ? (
                    <div id='background_2' className='background_2'>
                        <div className='container'>
                            <motion.header className='header-modal'>
                                {width > 500 ?

                                    <motion.nav
                                    className='nav-modal_new'
                                    variants={headerVariants_2}
                                    initial='hidden'
                                    animate='show'
                                    exit='exit'>
                                    <div className='nav-modal_new'>
                                    {links.map((link) => (
                                        <motion.div
                                            key={link.href}
                                            className='nav-item_modal'
                                        >
                                            <NavItemModal
                                                title={link.title}
                                                href={link.href}
                                                path={link.path}
                                                child={link.child}
                                            />
                                        </motion.div>
                                    ))}
                                    <motion.a
                                    style={{marginTop: 20}}
                                    className='nav-item_phone'
                                    href='tel: +32 492 52 91 43'>
                                    +32 492 52 91 43
                                    </motion.a>
                                    <motion.a className='nav-item_email' href='mailto: info@devColibri.biz'>
                                    info@devColibri.biz
                                    </motion.a>
                                    <motion.a
                                    className='nav-item_telegram'
                                    href='https://t.me/devColibri_biz_bot'
                                    target='_blank'>
                                    <svg
                                    className='telegram'
                                    version='1.1'
                                    id='Layer_1'
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='45'
                                    viewBox='0 0 455 455'>
                                    <g>
                                    <path
                                    d='M0,0v455h455V0H0z M384.814,100.68l-53.458,257.136
		c-1.259,6.071-8.378,8.822-13.401,5.172l-72.975-52.981c-4.43-3.217-10.471-3.046-14.712,0.412l-40.46,32.981
		c-4.695,3.84-11.771,1.7-13.569-4.083l-28.094-90.351l-72.583-27.089c-7.373-2.762-7.436-13.171-0.084-16.003L373.36,90.959
		C379.675,88.517,386.19,94.049,384.814,100.68z'
                                    fill='#fff'
                                    />
                                    <path
                                    d='M313.567,147.179l-141.854,87.367c-5.437,3.355-7.996,9.921-6.242,16.068
		l15.337,53.891c1.091,3.818,6.631,3.428,7.162-0.517l3.986-29.553c0.753-5.564,3.406-10.693,7.522-14.522l117.069-108.822
		C318.739,149.061,316.115,145.614,313.567,147.179z'
                                    fill='#fff'
                                    />
                                    </g>
                                    </svg>
                                    </motion.a>
                                    </div>
                                    <div className='nav'></div>
                                    </motion.nav>

                                :

                                    <nav
                                    className='nav-modal_new'
                                    variants={headerVariants_2}
                                    initial='hidden'
                                    animate='show'
                                    exit='exit'>
                                    <div className='nav-modal_new'>
                                    {links.map((link) => (
                                        <div
                                            key={link.href}
                                            className='nav-item_modal'
                                        >
                                            <NavItemModal
                                                title={link.title}
                                                href={link.href}
                                                path={link.path}
                                                child={link.child}
                                            />
                                        </div>
                                    ))}
                                    <a
                                    style={{marginTop: 20}}
                                    className='nav-item_phone'
                                    href='tel: +32 492 52 91 43'>
                                    +32 492 52 91 43
                                    </a>
                                    <a className='nav-item_email' href='mailto: info@devColibri.biz'>
                                    info@devColibri.biz
                                    </a>
                                    <a
                                    className='nav-item_telegram'
                                    href='https://t.me/devColibri_biz_bot'
                                    target='_blank'>
                                    <svg
                                    className='telegram'
                                    version='1.1'
                                    id='Layer_1'
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='45'
                                    viewBox='0 0 455 455'>
                                    <g>
                                    <path
                                    d='M0,0v455h455V0H0z M384.814,100.68l-53.458,257.136
		c-1.259,6.071-8.378,8.822-13.401,5.172l-72.975-52.981c-4.43-3.217-10.471-3.046-14.712,0.412l-40.46,32.981
		c-4.695,3.84-11.771,1.7-13.569-4.083l-28.094-90.351l-72.583-27.089c-7.373-2.762-7.436-13.171-0.084-16.003L373.36,90.959
		C379.675,88.517,386.19,94.049,384.814,100.68z'
                                    fill='#fff'
                                    />
                                    <path
                                    d='M313.567,147.179l-141.854,87.367c-5.437,3.355-7.996,9.921-6.242,16.068
		l15.337,53.891c1.091,3.818,6.631,3.428,7.162-0.517l3.986-29.553c0.753-5.564,3.406-10.693,7.522-14.522l117.069-108.822
		C318.739,149.061,316.115,145.614,313.567,147.179z'
                                    fill='#fff'
                                    />
                                    </g>
                                    </svg>
                                    </a>
                                    </div>
                                    <div className='nav'></div>
                                    </nav>

                                }


                            </motion.header>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </>
    )
}

export default Header
