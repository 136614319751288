import transition from "./transition";
export const animateVariants = {
    hero: {
        // hero animate
        hidden: false,
        show: {
            transition: {
                staggerChildren: 0.15,
            },
        },
        exit: {
            transition: {
                when: "afterChildren",
            },
        },
    },
    text: {
        // text animate
        hidden: {
            opacity: 0,
            y: "80%",
            skewY: 10,
        },
        show: {
            opacity: 1,
            y: 0,
            skewY: 0,
        },
        exit: {
            opacity: 0,
        },
    },
    divider: {
        // divider animate
        hidden: {
            opacity: 0,
            scaleX: 0,
            originX: 0,
        },
        show: {
            opacity: 1,
            scaleX: 1,
            originX: 0,
        },
        exit: {
            opacity: 0,
        },
    },
};

export const headerVariants_2 = {
    hidden: {
        opacity: 0,
        y: 150,
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            ...transition,
            delay: transition.duration / 124,
        },
    },
    exit: {
        opacity: 0,
    },
}
